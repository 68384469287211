@tailwind base;

@tailwind components;


body {
  background: transparent linear-gradient(180deg, #112241 0%, #202343 35%, #7b2c51 100%) 0% 0% no-repeat padding-box;
  /* background-image: url(../public/wordle.png); */
  /* background-size: 390px 884px; */
  background-position: top center;
  min-height: 100vh;
  font-family: 'museo-sans';
  @apply font-sans;
}

.card {
  @apply rounded bg-tan py-2 px-4 shadow-md w-full max-w-xs;
}

.card-item {
  @apply bg-white p-2 rounded mt-1 border-b border-gray-500 cursor-pointer my-2;
}

.btn {
  @apply border-white font-black p-2 rounded text-white uppercase border inline-block my-4 transition bg-opacity-0 bg-white
}

.btn:hover {
  @apply bg-opacity-20
}


.entire-content {
  margin: auto;
  width: 100px;
  perspective: 1000px;
  position: relative;
  padding-top: 600px;
  margin-top: -540px;
  margin-bottom: 240px;
}

.content-carrousel {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotar 50s infinite linear;
  transform-style: preserve-3d;
}


.content-carrousel figure {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.content-carrousel figure:nth-child(1) {
  transform: rotateY(0deg) translateZ(160px);
}

.content-carrousel figure:nth-child(2) {
  transform: rotateY(60deg) translateZ(160px);
}

.content-carrousel figure:nth-child(3) {
  transform: rotateY(120deg) translateZ(160px);
}

.content-carrousel figure:nth-child(4) {
  transform: rotateY(180deg) translateZ(160px);
}

.content-carrousel figure:nth-child(5) {
  transform: rotateY(240deg) translateZ(160px);
}

.content-carrousel figure:nth-child(6) {
  transform: rotateY(300deg) translateZ(160px);
}


.shadow {
  position: absolute;
  box-shadow: 0px 0px 20px 0px #000;
  border-radius: 1px;
}

.content-carrousel img {
  /* image-rendering: auto; */
  transition: all 300ms;
  width: 100%;
  height: auto;
  /* object-fit: cover; */
}


@keyframes rotar {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.fireworks * {
  fill: white !important
}

@tailwind utilities;